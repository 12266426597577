/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import { withRouter } from "react-router-dom";
import { logout } from "../../utils/helpers";
import $ from "jquery";

class SideBar extends React.Component {
  componentDidMount() {
    const pageLink = this.props.match.path;
    console.log(pageLink);
    $(".menuListing a").each(function () {
      $(this).removeClass("active");
    });
    $(".subMenu").slideUp(0);
    setTimeout(() => {
      $('.onlyChild a[dataAttr="' + pageLink + '"]').addClass("active");
      $('.subMenu a[dataAttr="' + pageLink + '"]').addClass("active");
      $('.menuListing a[dataAttr="' + pageLink + '"]')
        .closest(".hasChild")
        .find(".navItem")
        .addClass("activeComponent active");
      $('.menuListing a[dataAttr="' + pageLink + '"]')
        .closest(".hasChild")
        .find(".subMenu")
        .slideDown();
    }, 100);
  }
  changePage(e) {
    const pageLink = $(e.target).attr("dataAttr");
    this.props.history.push(pageLink);
    console.log(this);
    // setTimeout(() => {
    // 	console.log(pageLink)
    // 	$('.subMenu a[dataAttr="' + pageLink + '"]').addClass('active');
    // 	$('.subMenu a[dataAttr="' + pageLink + '"]').closest('.hasChild').find('.navItem').addClass('activeComponent');
    // 	$('.subMenu a[dataAttr="' + pageLink + '"]').closest('.hasChild').find('.subMenu').slideDown();
    // 	$('.onlyChild a[dataAttr="' + pageLink + '"]').addClass('active');

    
    // }, 100)
  }

  render() {
    return (
      <React.Fragment>
        <section className="sideBar">
          <div className="profileView">
            <div className="profileImg">
              <img src="/assets/images/user.jpg" alt="" />
            </div>
            <h3>
              {localStorage.getItem("adminName")
                ? localStorage.getItem("adminName")
                : "Admin"}
            </h3>
            <div className="profileBtn">
              <a href="#">
                <i className="fa fa-cog"></i>
              </a>
              <a href="#">
                <i className="fa fa-envelope"></i>
              </a>
              <a href="#" onClick={logout}>
                <i className="fa fa-sign-out"></i>
              </a>
            </div>
          </div>
          <div className="menuListing">
            <ul>
              <li className="onlyChild">
                <a
                  className=""
                  href="javascript:void(0)"
                  dataAttr="/dashboard"
                  onClick={this.changePage.bind(this)}
                >
                  Dashboard
                </a>
              </li>
              <li className="hasChild">
                <a
                  className="navItem"
                  href="javascript:void(0)"
                  dataAttr="/admin-list"
                  onClick={this.changePage.bind(this)}
                >
                  Admin Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="javascript:void(0)" dataAttr="/admin-list">
                      Admin Listing
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  User Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="javascript:void(0)"
                      dataAttr="/storage-provider-list"
                      onClick={this.changePage.bind(this)}>
                      Storage Provider Listing
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      dataAttr="/truck-provider-list"
                      onClick={this.changePage.bind(this)}>
                      Truck Provider Listing
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      dataAttr="/consumer-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Consumer Listing
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/refer-friend"
                      onClick={this.changePage.bind(this)}
                    >
                      Refer Listing
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/reservations"
                      onClick={this.changePage.bind(this)}
                    >
                      Reservations
                    </a>
                  </li>
                  <li>
                  {console.log('merged  asdfsdagfksdahgfk')}
                    <a
                      href="javascript:void(0)"
                      dataAttr="/leads"
                      onClick={this.changePage.bind(this)}
                    >
                      Leads
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/discounts"
                      onClick={this.changePage.bind(this)}
                    >
                      Discounts
                    </a>
                  </li> */}

                  {/*<li><a href="javascript:void(0)" dataAttr="/role-list" onClick={this.changePage.bind(this)}>Roles Listing</a></li>*/}
                  {/*<li><a href="javascript:void(0)" dataAttr="/add-role" onClick={this.changePage.bind(this)}>Add New Role</a></li>*/}
                </ul>
              </li>
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  Order Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/truck-orders-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Truck Orders
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/storage-unit-orders-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Storage Unit Orders
                    </a>
                  </li>
                </ul>
              </li>

              {/*<li className="onlyChild">*/}
              {/*	<a href="javascript:void(0)" dataAttr="/order-list" onClick={this.changePage.bind(this)}>Order Management</a>*/}
              {/*</li>*/}

              {/*<li className="hasChild">
								<a className="navItem" href="javascript:void(0)">Product Management</a>
								<ul className="subMenu">
									<li><a href="javascript:void(0)" dataAttr="/product-list" onClick={this.changePage.bind(this)}>Product Listing</a></li>
									<li><a href="javascript:void(0)" dataAttr="/add-product" onClick={this.changePage.bind(this)}>Add Product</a></li>
								</ul>
							</li>*/}
              <li className="hasChild">
                <a
                  className="navItem"
                  href="javascript:void(0)"
                  dataAttr="/feature-list"
                  onClick={this.changePage.bind(this)}
                >
                  Location Features
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="javascript:void(0)" dataAttr="/feature-list">
                      Location Features Listing
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a
                  className="navItem"
                  href="javascript:void(0)"
                  dataAttr="/amenities-list"
                  onClick={this.changePage.bind(this)}
                >
                  Amenities Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="javascript:void(0)" dataAttr="/amenities-list">
                      Amenities Listing
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a
                  className="navItem"
                  href="javascript:void(0)"
                  dataAttr="/add-ons-list"
                  onClick={this.changePage.bind(this)}
                >
                  Add Ons Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="javascript:void(0)" dataAttr="/add-ons-list">
                      Add Ons Categories List
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  Storage Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/storage-category-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Storage Search Categories
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  Truck Management
                </a>
                <ul className="subMenu">
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/truck-type-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Truck Type Listing
                    </a>
                  </li>
                  {/*dataAttr="/truck-type-list"
										   onClick={this.changePage.bind(this)}*/}
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/truck-category-list"
                      onClick={this.changePage.bind(this)}
                    >
                      Truck Search Categories
                    </a>
                  </li>
                </ul>
              </li>
              {/*======*/}
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  Email
                </a>
                <ul className="subMenu">
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/email-log"
                      onClick={this.changePage.bind(this)}
                    >
                      Read Email
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/email-templates"
                      onClick={this.changePage.bind(this)}
                    >
                      Email Template
                    </a>
                  </li>
                </ul>
              </li>
              <li className="hasChild">
                <a className="navItem" href="javascript:void(0)">
                  Settings
                </a>
                <ul className="subMenu">
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/api-setting"
                      onClick={this.changePage.bind(this)}
                    >
                      API Settings
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      dataAttr="/site-setting"
                      onClick={this.changePage.bind(this)}
                    >
                      Site Settings
                    </a>
                  </li>
                  {/*<li><a href="javascript:void(0)" dataAttr="/role-list" onClick={this.changePage.bind(this)}>Roles Listing</a></li>*/}
                  {/*<li><a href="javascript:void(0)" dataAttr="/add-role" onClick={this.changePage.bind(this)}>Add New Role</a></li>*/}
                </ul>
              </li>
              {/*<li class="hasChild">
								<a class="navItem" href="javascript:void(0)">Packages</a>
								<ul class="subMenu">
									<li><a href="javascript:void(0)" dataAttr="/packages" onClick={this.changePage.bind(this)}>All Packages</a></li>
									 <li><a href="javascript:void(0)" dataAttr="/add-product" onClick={this.changePage.bind(this)}>Consolidate Packages</a></li>
								</ul>
							</li>*/}

              {/*<li class="onlyChild">
								<a class="" href="javascript:void(0)" dataAttr="/shipment" onClick={this.changePage.bind(this)}>Shipments</a>
							</li>*/}

              {/*<li class="hasChild">
								<a class="navItem" href="javascript:void(0)">Address Book</a>
								<ul class="subMenu">
									<li><a href="javascript:void(0)" dataAttr="/customer-address-list" onClick={this.changePage.bind(this)}>Coustomer Address</a></li>
									<li><a href="javascript:void(0)" dataAttr="/wearhouse-address-list" onClick={this.changePage.bind(this)}>Wearhouse Address</a></li>
									<li><a href="javascript:void(0)" dataAttr="/add-address" onClick={this.changePage.bind(this)}>Add New Wearhouse</a></li>
								</ul>
							</li>*/}
            </ul>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(SideBar);
