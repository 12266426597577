/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";

class SiteSetting extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Settings",
      link: "#",
    },
    {
      title: "site setting",
      link: "#",
    },
  ];
  state = {
    settings: [],
    discount: { name: "", amount: "", _id: "" },
  };

  componentDidMount() {
    this.getSiteSettings();
    this.fechDiscount();
  }

  getSiteSettings = async () => {
    try {
      $("#loader").show();
      let res = await adminApiCall("setting/get_setting", "GET");
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        console.log(res);
        if (res?.status === 200 && res?.data) {
          this.setState({ settings: res?.data });
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };

  fechDiscount = async () => {
    const url = "https://dev-api.getspaceshuttle.com/api/admin/discount/list";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const res = data?.data;
      this.setState({
        ...this.state,
        discount: {
          name: res[0]?.name,
          amount: res[0]?.amount,
          _id: res[0]?._id,
        },
      });
      console.log(data, "i am data");
    } catch (err) {
      console.log(err);
    }
  };

  handleSubmit = async (event) => {
    try {
      $("#loader").show();

      event.preventDefault();
      const url =
        "https://dev-api.getspaceshuttle.com/api/admin/discount/update";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("adminToken")}`, // Use Bearer token if required
        },
        body: JSON.stringify({
          _id: this?.state?.discount?._id,
          amount: this?.state?.discount?.amount,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data) {
        this.fechDiscount();
        notify.show("Settings updated");
      }
      console.log(data, "fasdlkfjhlsdjkfshla");

      let formData = new FormData(event.target);
      let objectData = {};
      formData.forEach((value, key) => {
        objectData[key] = value;
      });
      objectData.id = this.state?.settings?._id;
      let res = await adminApiCall(
        "setting/site_setting",
        "POST",
        JSON.stringify(objectData)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  render() {
    console.log(this.state, "lfsadjkfhlsadkj");
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Location Feature"} />

        {/*SideBar*/}
        <SideBar />

        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />

        {/* main content starts here */}

        <section class="pageWrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="contentCard">
                  <div class="cardHeader clearfix">
                    <h1>Site Setting</h1>
                  </div>
                  <div class="cardBody">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>
                                  Email Validation at Checkout Discount
                                </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="number"
                                  name={"discount"}
                                  value={this.state?.discount?.amount}
                                  onChange={(e) =>
                                    this.setState({
                                      ...this.state?.settings,
                                      discount: {
                                        ...this.state?.discount,
                                        amount: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Default Deposit Price for Storage</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"storage_default_deposit_price"}
                                  value={
                                    this.state?.settings
                                      ?.storage_default_deposit_price
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings,
                                        storage_default_deposit_price:
                                          e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Default Deposit Price for Truck</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"truck_default_deposit_price"}
                                  value={
                                    this.state?.settings
                                      ?.truck_default_deposit_price
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings,
                                        truck_default_deposit_price:
                                          e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          {/* Refral Commision Storage*/}
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>
                                  Default Storage Referral Commission
                                </label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="storage_default_referral_commission"
                                    value={
                                      this.state?.settings
                                        ?.storage_default_referral_commission
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-numeric characters except '.' for decimals
                                      const numericValue = value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      this.setState({
                                        settings: {
                                          ...this.state?.settings,
                                          storage_default_referral_commission:
                                            numericValue,
                                        },
                                      });
                                    }}
                                    className="form-control"
                                  />
                                  <span
                                    className="input-group-addon"
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      right: "11px",
                                      top: "11px",
                                    }}
                                  >
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Refral Discount Storage*/}
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Default Storage Referral Discount</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="storage_default_referral_discount"
                                    value={
                                      this.state?.settings
                                        ?.storage_default_referral_discount
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-numeric characters except '.' for decimals
                                      const numericValue = value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      this.setState({
                                        settings: {
                                          ...this.state?.settings,
                                          storage_default_referral_discount:
                                            numericValue,
                                        },
                                      });
                                    }}
                                    className="form-control"
                                  />
                                  <span
                                    className="input-group-addon"
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      right: "11px",
                                      top: "11px",
                                    }}
                                  >
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Refral Commision truck */}
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Default Truck Referral Commission</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="truck_default_referral_commission"
                                    value={
                                      this.state?.settings
                                        ?.truck_default_referral_commission
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-numeric characters except '.' for decimals
                                      const numericValue = value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      this.setState({
                                        settings: {
                                          ...this.state?.settings,
                                          truck_default_referral_commission:
                                            numericValue,
                                        },
                                      });
                                    }}
                                    className="form-control"
                                  />
                                  <span
                                    className="input-group-addon"
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      right: "11px",
                                      top: "11px",
                                    }}
                                  >
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Refral Discount truck*/}
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Default Truck Referral Discount</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="truck_default_referral_discount"
                                    value={
                                      this.state?.settings
                                        ?.truck_default_referral_discount
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-numeric characters except '.' for decimals
                                      const numericValue = value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      this.setState({
                                        settings: {
                                          ...this.state?.settings,
                                          truck_default_referral_discount:
                                            numericValue,
                                        },
                                      });
                                    }}
                                    className="form-control"
                                  />
                                  <span
                                    className="input-group-addon"
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      right: "11px",
                                      top: "11px",
                                    }}
                                  >
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-12 text-right">
                          <button
                            type={"submit"}
                            class="myBtn"
                            onClick={this.register}
                          >
                            Save{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default SiteSetting;
