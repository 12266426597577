/**
 * @author Basit Raza <razabasit88@gmail.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2021-01-18
 */
import React from "react";
import PageHeader from "../../../components/page-header";
import ChangeTitle from "../../../components/change_page_title";
import PageFooter from "../../../components/page-footer";
import SideBar from "../../../components/side-bar";
import BreadCrumb from "../../../components/bread-crumb";
import $ from "jquery";
import {
  adminApiCall,
  adminApiCallForFormData,
} from "../../../utils/ajax_request";
import Notifications, { notify } from "react-notify-toast";
import Swal from "sweetalert2";

class ApiSetting extends React.Component {
  breadcrumbNavigation = [
    {
      title: "Settings",
      link: "#",
    },
    {
      title: "Api's setting",
      link: "#",
    },
  ];
  state = {
    settings: [],
  };

  componentDidMount() {
    this.getApiSettings();
  }

  getApiSettings = async () => {
    try {
      $("#loader").show();
      let res = await adminApiCall("setting/get_api", "GET");
      if (!res) {
        Swal.fire({
          title: "Error",
          text: "Something_went_wrong",
          icon: "error",
        });
        return false;
      }
      if (res.hasOwnProperty("status")) {
        console.log(res);
        if (res?.status === 200 && res?.data) {
          this.setState({ settings: res?.data });
        } else if (res?.status === 400) {
          Swal.fire({
            title: "Error",
            text: res?.message,
            icon: "error",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Something_gone_wrong",
        icon: "error",
      });
    } finally {
      $("#loader").hide();
    }
  };

  handleSubmit = async (event, api_type) => {
    try {
      $("#loader").show();
      event.preventDefault();
      let formData = new FormData(event.target);
      let objectData = {
        api_settings: {},
      };
      formData.forEach((value, key) => {
        objectData.api_settings[key] = value;
      });
      objectData.api_settings.api_type = api_type
        ? api_type
        : this.state?.settings?.api_type;
      let res = await adminApiCall(
        "setting/api",
        "POST",
        JSON.stringify(objectData)
      );
      if (!res) {
        notify.show("Something_went_wrong", "error", 2000);
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status == 200) {
          notify.show(res.message, "success", 2000);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (res.status == 400) {
          notify.show(res.message, "error", 2000);
        }
      }
    } catch (e) {
      console.log(e);
      notify.show("Something_gone_wrong", "error", 2000);
    } finally {
      $("#loader").hide();
    }
  };

  render() {
    return (
      /*Content Area*/
      <React.Fragment>
        <ChangeTitle title={"Api setting"} />

        {/*SideBar*/}
        <SideBar />
        {/*Page Header*/}
        <PageHeader />
        <Notifications options={{ zIndex: 10000 }} />
        <BreadCrumb navigation={this.breadcrumbNavigation} />
        {/* main content starts here */}
        <section className="pageWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="contentCard">
                  <div className="cardHeader clearfix">
                    <h1>Stripe Api Settings</h1>
                  </div>
                  <div className="cardBody">
                    <form onSubmit={(e) => this.handleSubmit(e,this.state?.settings?.stripe?.[0]?.api_type)}>
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Publishable Key</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"publishable_key"}
                                  value={
                                    this.state?.settings?.stripe?.[0]
                                      ?.publishable_key
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.stripe?.[0],
                                        publishable_key: e.target.value,
                                        api_type: "stripe",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Secret Key</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"secrete_key"}
                                  value={
                                    this.state?.settings?.stripe?.[0]
                                      ?.secrete_key
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.stripe?.[0],
                                        secrete_key: e.target.value,
                                        api_type: "stripe",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 text-right">
                          <button type={"submit"} className="myBtn">
                            Save{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*    =================== twilio api interface ===================== */}
            <div className="row">
              <div className="col-12">
                <div className="contentCard">
                  <div className="cardHeader clearfix">
                    <h1>Twilio's Api Settings</h1>
                  </div>
                  <div className="cardBody">
                    <form
                      onSubmit={(e) =>
                        this.handleSubmit(
                          e,
                          this.state?.settings?.twilio?.[0]?.api_type
                        )
                      }
                    >
                      <div className="row formWrapper">
                        {/*======== SENDER ID OR PHONE NUMBER ===========*/}
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Sender ID / Phone No</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"sender_id_or_phone"}
                                  value={
                                    this.state?.settings?.twilio?.[0]
                                      ?.sender_id_or_phone
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.twilio?.[0],
                                        sender_id_or_phone: e.target.value,
                                        api_type: "twilio",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*=================== END =========================*/}
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Account SID</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"account_sid"}
                                  value={
                                    this.state?.settings?.twilio?.[0]
                                      ?.account_sid
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.twilio?.[0],
                                        account_sid: e.target.value,
                                        api_type: "twilio",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Auth Token</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"auth_token"}
                                  value={
                                    this.state?.settings?.twilio?.[0]
                                      ?.auth_token
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.twilio?.[0],
                                        auth_token: e.target.value,
                                        api_type: "twilio",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 text-right">
                          <button type={"submit"} className="myBtn">
                            Save{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*    =============== postmark api interface ========================= */}
            <div className="row">
              <div className="col-12">
                <div className="contentCard">
                  <div className="cardHeader clearfix">
                    <h1>Postmark Api Settings</h1>
                  </div>
                  <div className="cardBody">
                    <form
                      onSubmit={(e) =>
                        this.handleSubmit(
                          e,
                          this.state?.settings?.postmark?.[0]?.api_type
                        )
                      }
                    >
                      <div className="row formWrapper">
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Sender Signature</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"sender_signature"}
                                  value={
                                    this.state?.settings?.postmark?.[0]
                                      ?.sender_signature
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.postmark?.[0],
                                        sender_signature: e.target.value,
                                        api_type: "postmark",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="form-group inlineField">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-4 col-lg-3">
                                <label>Api Key</label>
                              </div>
                              <div className="col-12 col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  name={"api_key"}
                                  value={
                                    this.state?.settings?.postmark?.[0]?.api_key
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      settings: {
                                        ...this.state?.settings?.postmark?.[0],
                                        api_key: e.target.value,
                                        api_type: "postmark",
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 text-right">
                          <button type={"submit"} className="myBtn">
                            Save{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PageFooter />
      </React.Fragment>
    );
  }
}

export default ApiSetting;
